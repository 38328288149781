<template>
  <div class="hold-transition">
    <div
      class="alert alert-default-warning border border-warning col-md-12 shadow"
    >
      <h5 class="text-center">
        <i class="icon fas fa-exclamation-triangle"></i>
        Atención!
      </h5>
      <p class="text-center mb-0">
        Es importante que los correos electrónicos estén debidamente
        parametrizados para las empresas con el fin de asegurar el envío
        correcto de los mismos. Si aún no se encuentran configurados, por favor,
        pónganse en contacto con el administrador de Frontera para realizar la
        parametrización correspondiente.
      </p>
    </div>
    <div
      class="row"
      v-for="(tipoSer, index) in $parent.form.tipos_servicios"
      :key="index"
    >
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card card-info card-outline collapsed-card">
          <div class="card-header pt-2 pb-2">
            <h3 class="card-title">
              <i class="fas fa-clipboard-list"></i> Detalle
              <span class="badge badge-info">
                {{ index + 1 }}
              </span>
            </h3>
            <div class="card-tools">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
          <div class="card-body">
            <div class="card">
              <div class="card-header card text-bold">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-0">
                      <span class="badge badge-info"> Tipos Vehículos</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12 table-responsive">
                    <table
                      class="table table-bordered table-striped table-hover table-sm text-sm "
                    >
                      <thead class="bg-gray text-center">
                        <tr class="text-center">
                          <th class="text-nowrap">
                            Acciones
                          </th>
                          <th class="text-nowrap">Tipo Servicio</th>
                          <th class="text-nowrap">
                            Vehiculo/Equipo/Complementarios
                          </th>
                          <th class="text-nowrap">Día/Viaje</th>
                          <th class="text-nowrap">
                            Cantidad Vehículos a Solicitar
                          </th>
                          <th class="text-nowrap">
                            Tiempo Requerido del Servicio (Dias / Cant)
                          </th>
                          <th class="text-nowrap">Transporta Material</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">
                            <div class="btn-group">
                              <button
                                type="button"
                                class="btn bg-primary"
                                v-if="acciones[index].indice == null"
                                @click="addAsignacion(index)"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                              <button
                                class="btn bg-danger"
                                @click="cancelTipoServ(index)"
                                v-if="
                                  acciones[index].indice == 2 &&
                                    $parent.form.tipos_servicios.some((s) =>
                                      [3].includes(s.estado)
                                    )
                                "
                              >
                                <i class="fas fa-trash"></i>
                              </button>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ tipoSer.tipoServicio }}
                          </td>
                          <td class="text-center">
                            <div v-if="tipoSer.tipo_servicio == 1">
                              {{ tipoSer.tipo_vehiculo.nombre }}
                              {{
                                tipoSer.tipoRemolque
                                  ? " - " + tipoSer.tipoRemolque
                                  : ""
                              }}
                            </div>
                            <div v-else-if="tipoSer.tipo_servicio == 2">
                              {{ tipoSer.nTipoEquipo }}
                            </div>
                            <div v-else-if="tipoSer.tipo_servicio == 3">
                              {{ tipoSer.tipoComplemen }}
                            </div>
                          </td>
                          <td class="text-center">
                            <div v-if="tipoSer.tipo_cantidad == 1">
                              <span class="badge bg-lime">Días</span>
                            </div>
                            <div v-else>
                              <span class="badge bg-navy">Viajes</span>
                            </div>
                          </td>
                          <td class="text-center">
                            {{ tipoSer.cantidad_vehiculos }}
                          </td>
                          <td class="text-center">
                            {{ tipoSer.cantidad }}
                          </td>
                          <td class="text-center">
                            <div v-if="tipoSer.material_inventarios == 1">
                              <span class="badge badge-success">Si</span>
                            </div>
                            <div v-else>
                              <span class="badge badge-danger">No</span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="card" v-if="acciones[index].indice == 2">
                  <div class="card-body">
                    <template v-for="(cantVh, i) in tipoSer.cantidad">
                      <div
                        class="row justify-content-center"
                        :class="i > 0 ? 'mt-3' : ''"
                        :key="i"
                      >
                        <hr v-if="i > 0" class="col-md-12 w-100" />
                        <div class="col-md-12 text-center">
                          <strong
                            >Asignación
                            <span class="badge alert-default-primary">{{
                              i + 1
                            }}</span></strong
                          >
                        </div>
                        <!-- Acciones -->
                        <div class="col-md-1" v-if="formTipo[index][i]">
                          <button
                            type="button"
                            class="btn bg-success mt-3"
                            @click="
                              [
                                saveUpdateTipoServSolicitud(index, i),
                                (formTipo[index][i].bloquear = true),
                                (formTipo[index][i].posicion = i),
                              ]
                            "
                            v-if="
                              formTipo[index][i].fecha_inicial &&
                                formTipo[index][i].empresa_id &&
                                formTipo[index][i].cantidad_vehiculos > 0 &&
                                formTipo[index][i].cantidad_servicios > 0 &&
                                !formTipo[index][i].bloquear &&
                                $parent.form.tipos_servicios.some((s) =>
                                  [3].includes(s.estado)
                                )
                            "
                          >
                            <i class="fas fa-save"></i>
                          </button>
                          <button
                            type="button"
                            class="btn bg-navy mt-3"
                            @click="editDet(index, i)"
                            v-if="
                              $parent.form.tipos_servicios.some((s) =>
                                [3].includes(s.estado)
                              ) &&
                                formTipo[index][i].bloquear &&
                                acciones[index].indice == 2 &&
                                (editableIndex === null || editableIndex === i)
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                        <!-- Fecha Inicial -->
                        <div class="col-md-2" v-if="formTipo[index][i]">
                          <label for="fecha_inical">Fecha Inicial </label>
                          <input
                            type="date"
                            class="form-control form-control-sm"
                            id="cantidad_vehiculos"
                            v-model="formTipo[index][i].fecha_inicial"
                            :class="
                              formTipo[index][i].fecha_inicial
                                ? 'border border-success'
                                : 'border border-danger'
                            "
                            :disabled="formTipo[index][i].bloquear"
                          />
                        </div>
                        <!-- Empresa -->
                        <div class="col-md-5" v-if="formTipo[index][i]">
                          <label for="empresa">Empresa </label>
                          <v-select
                            :class="[
                              formTipo[index][i].empresa_id
                                ? 'border border-success'
                                : 'border border-danger',
                              'h-30',
                              $store.getters.getDarkMode ? 'dark-vselect' : '',
                            ]"
                            v-model="empresa[index][i]"
                            :append-to-body="true"
                            placeholder="Empresa"
                            label="razon_social"
                            class="form-control form-control-sm p-0 table-responsive"
                            :options="listasForms.empresas"
                            @input="selectEmpresa(index, i)"
                            :disabled="formTipo[index][i].bloquear"
                          ></v-select>
                        </div>
                        <!-- Cantidad Vehículos -->
                        <div class="col-md-2" v-if="formTipo[index][i]">
                          <label for="cantidad_vehiculo"
                            >Cantidad Vehículos
                          </label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="cantidad_vehiculos"
                            v-model="formTipo[index][i].cantidad_vehiculos"
                            @input="validarCantVh(index, i)"
                            :class="
                              formTipo[index][i].cantidad_vehiculos &&
                              formTipo[index][i].cantidad_vehiculos > 0
                                ? 'border border-success'
                                : 'border border-danger'
                            "
                            :disabled="formTipo[index][i].bloquear"
                          />
                        </div>
                        <!-- Cantidad Servicios -->
                        <div class="col-md-2" v-if="formTipo[index][i]">
                          <label for="cantidad_servicios"
                            >Cantidad Servicios
                          </label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            id="cantidad_servicios"
                            v-model="formTipo[index][i].cantidad_servicios"
                            @input="validarCantServ(index, i)"
                            :class="
                              formTipo[index][i].cantidad_servicios &&
                              formTipo[index][i].cantidad_servicios > 0
                                ? 'border border-success'
                                : 'border border-danger'
                            "
                            :disabled="formTipo[index][i].bloquear"
                          />
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <!-- Rutas -->
            <div class="card" v-if="tipoSer.rutas != undefined">
              <div class="card-header card text-bold">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-0">
                      <span class="badge badge-info"> Rutas</span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="tipoSer.rutas.length > 0">
                <CsAsignacionesRutas
                  ref="csAsignacionesRutas"
                  :indice="index"
                  :dato="tipoSer"
                />
              </div>
            </div>
            <!-- Detalle Carga -->
            <div class="card" v-if="tipoSer.det_cargas != undefined">
              <div class="card-header card text-bold">
                <div class="row">
                  <div class="col-md-12">
                    <h5 class="mb-0">
                      <span class="badge badge-info">
                        Detalle Carga
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12" v-show="tipoSer.det_cargas.length > 0">
                <CsAsignacionesDetCarga
                  ref="csAsignacionesDetCarga"
                  :indice="index"
                  :dato="tipoSer"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import CsAsignacionesRutas from "./CsAsignacionesRutas";
import CsAsignacionesDetCarga from "./CsAsignacionesDetCarga";

export default {
  name: "CsAsignacionesTipoVehiculo",
  components: {
    vSelect,
    CsAsignacionesRutas,
    CsAsignacionesDetCarga,
  },

  data() {
    return {
      tipoServSolicitudes: [],
      formTipo: [],
      editableIndex: null, // Índice de la fila en edición
      asignaciones: [],
      empresa: [],
      listasForms: {
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      acciones: [],
      flag_formulario: true,
    };
  },

  methods: {
    selectEmpresa(index, i) {
      this.formTipo[index][i].empresa_id = null;
      if (
        this.empresa[index][i] !== null &&
        this.empresa[index][i] !== undefined
      ) {
        this.formTipo[index][i].empresa_id = this.empresa[index][i].id;
      }
    },

    async getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async saveTipoServSolicitudTotal() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/cs/asignacionesSolicitudesNacionalesTipoServ",
        data: {
          cs_solicitud_nacional_id: this.$parent.form.id,
          tipoServSolicitudes: this.$parent.form.tipos_servicios,
          asignaciones: this.asignaciones,
        },
      })
        .then(async (response) => {
          this.cargando = false;
          await this.getTipoServSolicitudes;
        })
        .catch((e) => {
          this.cargando = false;
          this.message += e.message;
        });
    },

    validaTipoServicio() {
      this.flag_formulario = true;
      // Calcular la cantidad total de servicios en asignaciones
      const totalAsignacionesServicios = this.asignaciones.reduce(
        (sum, item) => sum + parseFloat(item.cantidad_vehiculos),
        0
      );

      // Calcular la cantidad total de servicios en tipos_servicios
      const totalTiposServicios = this.$parent.form.tipos_servicios.reduce(
        (sum, item) => sum + item.cantidad_vehiculos,
        0
      );

      if (totalAsignacionesServicios == totalTiposServicios) {
        this.flag_formulario = false;
      }
    },

    arrayForm() {
      this.formTipo = this.$parent.form.tipos_servicios.map((tipoServicio) => {
        if (tipoServicio.asignaciones && tipoServicio.asignaciones.length > 0) {
          return tipoServicio.asignaciones.map((asignacion, index) => ({
            id: asignacion.id,
            cs_solicitud_nacional_tipo_serv_id: tipoServicio.id,
            posicion: index,
            fecha_inicial: asignacion.fecha_inicial,
            empresa_id: asignacion.empresa_id,
            cantidad_vehiculos: asignacion.cantidad_vehiculos,
            cantidad_servicios: asignacion.cantidad_servicios,
            bloquear: true,
          }));
        } else {
          return Array.from({ length: tipoServicio.cantidad }, () => ({
            id: null,
            cs_solicitud_nacional_tipo_serv_id: tipoServicio.id,
            posicion: null,
            fecha_inicial: null,
            empresa_id: null,
            cantidad_vehiculos: null,
            cantidad_servicios: null,
            bloquear: true,
          }));
        }
      });

      this.empresa = this.$parent.form.tipos_servicios.map((tipoServicio) => {
        if (tipoServicio.asignaciones && tipoServicio.asignaciones.length > 0) {
          return tipoServicio.asignaciones.map((asignacion, index) => ({
            id: asignacion.empresa.id,
            razon_social: asignacion.empresa.razon_social,
          }));
        } else {
          return Array.from({ length: tipoServicio.cantidad }, () => ({
            id: null,
            razon_social: null,
          }));
        }
      });

      this.acciones = Array.from(
        { length: this.$parent.form.tipos_servicios.length },
        () => ({
          indice: null,
        })
      );

      this.asignaciones = this.$parent.form.tipos_servicios.flatMap(
        (tipoServicio, index) =>
          tipoServicio.asignaciones && tipoServicio.asignaciones.length > 0
            ? tipoServicio.asignaciones.map((asignacion, i) => ({
                id: asignacion.id,
                cs_solicitud_nacional_tipo_serv_id:
                  asignacion.cs_solicitud_nacional_tipo_serv_id,
                empresa_id: asignacion.empresa_id,
                fecha_inicial: asignacion.fecha_inicial,
                cantidad_vehiculos: asignacion.cantidad_vehiculos,
                cantidad_servicios: asignacion.cantidad_servicios,
                posicionDetalle: index,
                posicion: i,
              }))
            : []
      );
    },

    addAsignacion(index) {
      this.acciones[index].indice = 2;
    },

    cancelTipoServ(index) {
      this.acciones[index].indice = null;
      this.limpiarForm(index);
    },

    saveUpdateTipoServSolicitud(index, i) {
      // Obtener los datos a asignar o actualizar
      const newAsignacion = {
        id: null,
        cs_solicitud_nacional_tipo_serv_id: this.formTipo[index][i]
          .cs_solicitud_nacional_tipo_serv_id,
        empresa_id: this.formTipo[index][i].empresa_id,
        fecha_inicial: this.formTipo[index][i].fecha_inicial,
        cantidad_vehiculos: this.formTipo[index][i].cantidad_vehiculos,
        cantidad_servicios: this.formTipo[index][i].cantidad_servicios,
        posicionDetalle: index,
        posicion: i,
      };

      // Buscar si ya existe en el array `asignaciones`
      const existingIndex = this.asignaciones.findIndex(
        (asignacion) =>
          asignacion.cs_solicitud_nacional_tipo_serv_id ===
            newAsignacion.cs_solicitud_nacional_tipo_serv_id &&
          asignacion.posicion === newAsignacion.posicion
      );

      if (existingIndex !== -1) {
        // Si existe, actualiza el registro existente
        this.asignaciones[existingIndex] = newAsignacion;
      } else {
        // Si no existe, agrega un nuevo registro
        this.asignaciones.push(newAsignacion);
      }
      this.editableIndex = null;
    },

    validarCantVh(index, i) {
      this.formTipo[index][i].posicion = i;
      if (
        this.formTipo[index][i].cantidad_vehiculos >
        this.$parent.form.tipos_servicios[index].cantidad_vehiculos
      ) {
        this.$swal({
          icon: "error",
          title:
            "La cantidad de vehículos digitada " +
            this.formTipo[index][i].cantidad_vehiculos +
            " es mayor a la cantidad general del detalle " +
            this.$parent.form.tipos_servicios[index].cantidad_vehiculos,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.formTipo[index][i].cantidad_vehiculos = null;
      }

      const existingIndex = this.asignaciones.findIndex(
        (asignacion) =>
          asignacion.cs_solicitud_nacional_tipo_serv_id ===
            this.formTipo[index][i].cs_solicitud_nacional_tipo_serv_id &&
          asignacion.posicion == i
      );

      let totalAsignacionesServicios = null;
      if (existingIndex !== -1) {
        // Si existe, actualiza el registro existente
        totalAsignacionesServicios = this.asignaciones.reduce(
          (sum, item) =>
            sum + parseFloat(this.formTipo[index][i].cantidad_vehiculos),
          0
        );
      } else {
        if (
          this.asignaciones.length > 0 &&
          this.asignaciones.some(
            (a) =>
              a.cs_solicitud_nacional_tipo_serv_id ===
              this.formTipo[index][i].cs_solicitud_nacional_tipo_serv_id
          )
        ) {
          totalAsignacionesServicios = this.asignaciones.reduce(
            (sum, item) => sum + parseFloat(item.cantidad_vehiculos),
            0 + parseFloat(this.formTipo[index][i].cantidad_vehiculos)
          );
        } else {
          totalAsignacionesServicios = this.formTipo[index][i]
            .cantidad_vehiculos;
        }
      }

      if (
        totalAsignacionesServicios >
          this.$parent.form.tipos_servicios[index].cantidad_vehiculos &&
        this.formTipo[index][i].posicion == i
      ) {
        this.$swal({
          icon: "error",
          title:
            "Se paso el limite de cantidad de vehículos, limite: " +
            this.$parent.form.tipos_servicios[index].cantidad_vehiculos,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.formTipo[index][i].cantidad_vehiculos = null;
      }
    },

    validarCantServ(index, i) {
      let cantTotal =
        parseFloat(this.$parent.form.tipos_servicios[index].cantidad) *
        parseFloat(this.$parent.form.tipos_servicios[index].cantidad_vehiculos);

      const existingIndex = this.asignaciones.findIndex(
        (asignacion) =>
          asignacion.cs_solicitud_nacional_tipo_serv_id ===
            this.formTipo[index][i].cs_solicitud_nacional_tipo_serv_id &&
          asignacion.posicion == i
      );

      let totalAsignacionesServicios = null;
      if (existingIndex !== -1) {
        // Si existe, actualiza el registro existente
        totalAsignacionesServicios = this.asignaciones.reduce(
          (sum, item) =>
            sum + parseFloat(this.formTipo[index][i].cantidad_servicios),
          0
        );
      } else {
        totalAsignacionesServicios = this.asignaciones.reduce(
          (sum, item) => sum + parseFloat(item.cantidad_servicios),
          0 + parseFloat(this.formTipo[index][i].cantidad_servicios)
        );
      }

      // Tipo Cantidad Día = 1 / Viaje = 2
      /* if (this.$parent.form.tipos_servicios[index].tipo_cantidad == 2) {
        if (this.formTipo[index][i].cantidad_servicios > cantTotal) {
          this.$swal({
            icon: "error",
            title:
              "La cantidad de servicios digitada " +
              this.formTipo[index][i].cantidad_servicios +
              " es mayor a la cantidad general del detalle " +
              cantTotal,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.formTipo[index][i].cantidad_servicios = null;
        }

        if (totalAsignacionesServicios > cantTotal) {
          this.$swal({
            icon: "error",
            title:
              "Se paso el limite de cantidad de servicios, limite: " +
              cantTotal,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.formTipo[index][i].cantidad_servicios = null;
        }
      } else { */
      if (
        this.formTipo[index][i].cantidad_servicios >
        this.$parent.form.tipos_servicios[index].cantidad
      ) {
        this.$swal({
          icon: "error",
          title:
            "La cantidad de servicios digitada " +
            this.formTipo[index][i].cantidad_servicios +
            " es mayor a la cantidad general del detalle " +
            this.$parent.form.tipos_servicios[index].cantidad,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.formTipo[index][i].cantidad_servicios = null;
      }

      if (
        totalAsignacionesServicios >
        this.$parent.form.tipos_servicios[index].cantidad_servicios
      ) {
        this.$swal({
          icon: "error",
          title:
            "Se paso el limite de cantidad de servicios, limite: " +
            this.$parent.form.tipos_servicios[index].cantidad_servicios,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.formTipo[index][i].cantidad_servicios = null;
      }
      /*  } */
    },

    limpiarForm(index) {
      const indice = this.$parent.form.tipos_servicios.findIndex(
        (tipoServicio) =>
          tipoServicio.cs_solicitud_nacional_tipo_serv_id === index
      );
      if (indice) {
        this.editableIndex = null;
        this.asignaciones = this.asignaciones.filter(
          (asignacion) => asignacion.posicionDetalle !== index
        );
        this.formTipo = this.$parent.form.tipos_servicios.map(
          (tipoServicio) => {
            return Array.from({ length: tipoServicio.cantidad }, () => ({
              cs_solicitud_nacional_tipo_serv_id: tipoServicio.id,
              posicion: null,
              fecha_inicial: null,
              empresa_id: null,
              cantidad_vehiculos: null,
              cantidad_servicios: null,
              bloquear: true,
            }));
          }
        );

        this.empresa = this.$parent.form.tipos_servicios.map((tipoServicio) => {
          return Array.from({ length: tipoServicio.cantidad }, () => ({
            id: null,
            razon_social: null,
          }));
        });
      }
    },

    editDet(index, i) {
      if (this.editableIndex === null || this.editableIndex !== i) {
        this.editableIndex = i; // Bloquea el otro botón del índice de `acciones`
        this.formTipo[index][i].bloquear = false;
      } else {
        this.editableIndex = null; // Desbloquea cuando se cierra la edición
        this.formTipo[index][i].bloquear = true;
      }
    },
  },

  created() {
    this.arrayForm();
    this.tipoServicioInterval = setInterval(() => {
      this.validaTipoServicio();
    }, 5000);
  },

  beforeDestroy() {
    // Limpiar el intervalo al destruir el componente
    clearInterval(this.tipoServicioInterval);
  },

  async mounted() {
    await this.getEmpresas();
  },
};
</script>
